<template>
    <div class="d-flex justify-content-center align-items-center" v-if="showPreloader" style="height: 90vh; z-index: 10; position: absolute; left: 50%;">
        <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
        <b-row>
            <b-col cols="12">
                <b-card class="my-card m-0">
                    <b-table
                            striped
                            small
                            id="myTable"
                            bordered
                            selectable
                            selected-variant="primary"
                            select-mode="single"
                            responsive="sm"
                            :items="history"
                            :fields="fields" 
                            head-row-variant="secondary"
                        >
                            <template #cell(checkbox)="row">
                                <input type="checkbox" :value="row.item.id">
                            </template>
                            <template #cell(create_user)="data">
                                {{data.item.create_user ? data.item.create_user.login : ''}}
                            </template>
                            <template #cell(to_addresses)="data">
                                <span v-for="(to_address, idx) in data.item.to_addresses" :key="idx">
                                    <span v-if="to_address">
                                        <span v-if="to_address.type === 'fast_address' || to_address.type === 'address_point'">
                                            {{ to_address.name + ';' }}
                                        </span>
                                        <span v-if="to_address.type === 'address'">
                                            <span v-if="to_address.street_type == 'микрорайон'">
                                                <span>
                                                {{ to_address.street + ' ' + to_address.street_type + ', ' + to_address.name + ';' }}
                                                </span>
                                            </span>
                                            <span v-else>
                                                <span>
                                                {{ to_address.street_type + ' ' + to_address.street + ', ' + to_address.name + ';' }}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </template>
                            <template #cell(from_address)="data">
                                <div v-if="data.item.from_address">
                                    <div v-if="data.item.from_address.type === 'fast_address' || data.item.from_address.type === 'address_point'">
                                        {{ data.item.from_address.name }}
                                    </div>
                                    <div v-if="data.item.from_address.type === 'address'">
                                        <div v-if="data.item.from_address.street_type == 'микрорайон'">
                                            <span>
                                                {{ data.item.from_address.street + ' ' + data.item.from_address.street_type + ', ' + data.item.from_address.name }}
                                            </span>
                                        </div>
                                        <div v-else>
                                            <span>
                                                {{ data.item.from_address.street_type + ' ' + data.item.from_address.street + ', ' + data.item.from_address.name }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #cell(performer_name)="data">
                                {{data.item.performer==null ? '' :data.item.performer.last_name+' '+data.item.performer.first_name}}
                            </template>
                            <template #cell(performer_phone)="data">
                                {{ data.item.performer ? data.item.performer.phone : '' }}
                            </template>
                            <template #cell(performer_login)="data">
                                <span @dblclick="getLogin(data)" v-if="data.item.performer" class="reference-field">
                                    {{ data.item.id ? data.item.performer.login : '' }}
                                </span>
                              </template>
                            <template #cell(performer_transport)="data">
                                {{data.item.performer==null || data.item.performer.transport==null? '' : data.item.performer.transport.model + ` ${data.item.performer.transport.color}` + ` ${data.item.performer.transport.car_number}`}}
                            </template>
                            <template #cell(distance)="data">
                                {{data.item.distance!=null ? data.item.distance.toFixed(1)+'км' : ''}}
                            </template>
                            <template #cell(allowances)="data">
                                <span v-for="(allowance, index) in data.item.allowances" :key="allowance.id">
                                    {{ data.item.allowances.length - 1 === index ? allowance.name : allowance.name + '; ' }}
                                </span>
                            </template>
                            <template #cell(client_status) = "data">
                                {{ data.item.client_status == 0 ? 'Не оповещён' : 'Оповещён'}}
                            </template>
                            <template  #cell(report)="data">
                                <span @dblclick="getAssigment(data)" class="reference-field">
                                    (***)
                                </span>
                            </template>   
                            <template #cell(history_call)="data">
                                <span @dblclick="getHistoryCall(data)" class="reference-field">
                                    (***)
                                </span>
                            </template>  
                        </b-table>
            </b-card>
        </b-col>
        </b-row>
    </div>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import VueContext from 'vue-context'
export default {
    components: {
        VueContext,
    },
    data(){
        return{
            history: [],
            showPreloader: false,
            refreshTimer: null,
            items: [],
            fields: [
                { key: 'checkbox', label: '', thStyle: {width: '30px'} },
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'} },
                { key: 'division', label: 'Подразделение', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_login', label: 'Логин водителя', sortable: true, thStyle: {width: '150px'} },
                { key: 'from_address', label: 'Откуда', sortable: true, thStyle: {width: '220px'} },
                { key: 'to_addresses', label: 'Куда', sortable: true, thStyle: {width: '220px'} },
                { key: 'price', label: 'Цена', sortable: true, thStyle: {width: '150px'} },
                { key: 'bonus_price', label: 'Безнал', sortable: true, thStyle: {width: '150px'} },
                { key: 'phone', label: 'Номер телефона', sortable: true, thStyle: {width: '150px'} },
                { key: 'dop_phone', label: 'Доп. номер', sortable: true, thStyle: {width: '150px'} },
                { key: 'report', label: 'Запросы', sortable: true, thStyle: {width: '150px'} },
                { key: 'past_minute', label: 'ВП', sortable: true, thStyle: {width: '40px'} },
                { key: 'tariff', label: 'Тариф', sortable: true, thStyle: {width: '150px'} },
                { key: 'status', label: 'Статус', sortable: true, thStyle: {width: '150px'} },
                { key: 'created_at', label: 'Время поступления', sortable: true, thStyle: {width: '150px'} },
                { key: 'date_time', label: 'На время', sortable: true, thStyle: {width: '150px'} },
                { key: 'assignmentBy', label: 'Назначил', sortable: true, thStyle: {width: '150px'} },
                { key: 'stat_for_drivers', label: 'Доп. инфо для водителя', sortable: true, thStyle: {width: '200px'} },
                { key: 'info_for_operator', label: 'Доп. инфо для оператора', sortable: true, thStyle: {width: '200px'} },
                { key: 'performer_name', label: 'Водитель', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_transport', label: 'Автомобиль', sortable: true, thStyle: {width: '150px'} },
                { key: 'create_user', label: 'Создал', sortable: true, thStyle: {width: '150px'} },
                { key: 'performer_phone', label: 'Номер водителя', sortable: true, thStyle: {width: '150px'} },
                { key: 'client_status', label: 'Статус клиента', sortable: true, thStyle: {width: '150px'} },
                { key: 'type', label: 'Тип', sortable: true, thStyle: {width: '150px'} },
                { key: 'model_editor_login', label: 'Mодель редактор логин', sortable: true, thStyle: {width: '150px'} },
                { key: 'allowances', label: 'Надбавки', sortable: true, thStyle: {width: '150px'} },
                { key: 'distance', label: 'Расстояние', sortable: true, thStyle: {width: '150px'} },
                { key: 'history_call', label: 'История звонков', sortable: true, thStyle: {width: '150px'} },
                { key: 'commission_price', label: 'Коммиссия', sortable: true, thStyle: {width: '150px'} },
            ],
            id: this.$route.query.filter
        }
    },
    methods: {
        getAssigment(data){
            this.$store.commit('draggableTab/ADD_TAB',['Запросы ' + data.item.order_id, `/order/assigment`, data.item.order_id  ])
            this.$router.push({name: 'report/assigment', query:{filter: data.item.order_id}})
        },
        getHistoryCall(data){
            this.$store.commit('draggableTab/ADD_TAB',['Звонки ' + data.item.order_id, `/order/history-call`, data.item.order_id  ])
            this.$router.push({name: 'orderHistoryCall', query:{filter: data.item.order_id}})
        },
        refresh(){
        if (document.visibilityState !== 'visible') return
        let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
        let access = JSON.parse(localStorage.getItem('userData'))
        let query = {
            division_id: access.division_id
        }
        if (access.role_id == 2) {
            this.$http
            .get('orders', {params: param})
            .then(res => {
                this.showPreloader = true
                setTimeout(() => {
                    this.history = []
                    this.showPreloader = false;
                    this.history = res.data
                    this.$store.commit('pageData/setdataCount', this.history.length)
                }, "200")
                this.$store.commit('excel/setOrders', this.history)
                this.$store.commit('REFRESH_DATA', false)
            })
        }else{
            this.$http
            .get('orders', {params: param})
            .then(res => { 
                this.history = []
                this.$store.commit('excel/setOrders', this.history)
                this.showPreloader = true
                setTimeout(() => {
                    this.history = res.data
                    this.showPreloader = false;
                    this.$store.commit('pageData/setdataCount', this.history.length)
                }, "200")
                this.$store.commit('REFRESH_DATA', false)
            })
        }
    },
},
mounted() {
        this.$store.commit('pageData/setdataCount', null)
        resizeable()
        this.refreshTimer = setInterval(this.resizeable, 3000);
        this.showPreloader = false
        this.$http
            .get(`orders/${this.$route.query.filter}/history`)
            .then(res => {
                this.history = res.data
                this.showPreloader = false
                this.$store.commit('pageData/setdataCount', this.history.length)
            })
    },
    beforeDestroy() {
    clearInterval(this.refreshTimer)
   },
watch: {
        '$route.query.filter': function(newPage, oldPage) {
            if(newPage !== oldPage){
                this.showPreloader = true
                this.$http
                    .get(`orders/${this.$route.query.filter}/history`)
                    .then(res => {
                    this.history = res.data
                    this.showPreloader = false
                    this.$store.commit('pageData/setdataCount', this.history.length)
                })
                    
            }
        },
    },      
fetchingNewData(val){
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            if (val){
                this.history = []
                this.$http
                .get(`${this.$route.name}`, {params: param})
                .then(res =>{
                    this.history = res.data
                    this.$store.commit('pageData/setdataCount', this.history.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style>
 